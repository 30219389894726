// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";
import type { LeadHash, LeadParamsType } from "bus/lead/models";
import type { SigninType } from "bus/auth/models";
import type { BaseQueryKey } from "models/Base";

// Domains
import { leadKeys } from "bus/lead/store";

// Utils
import { leadService } from "bus/lead/service";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";

type UseCallbackCreateVerifiedLead = (
  data: SigninType,
) => Promise<[HttpErrorType | null, LeadHash | null]>;

type UseLeadVerifiedCreateMutationType = {
  loadingVerifiedLeadCreate: boolean;
  onCreateLeadVerified: UseCallbackCreateVerifiedLead;
};

export const useLeadVerifiedCreateMutation = (): UseLeadVerifiedCreateMutationType => {
  const queryKey = [leadKeys.verifiedLead];

  const createLeadVerifiedProps = useMutationAsync<
    LeadHash,
    SigninType,
    SigninType,
    BaseQueryKey,
    LeadParamsType
  >({
    key: queryKey,
    name: "createVerifiedLeadMutation",
    fetcher: leadService.createVerifiedLead,
  });

  const onCreateLeadVerified = useCallback<UseCallbackCreateVerifiedLead>(async (data) => {
    try {
      const createLeadVerifiedResult = await createLeadVerifiedProps.mutateAsync(data);
      return [null, createLeadVerifiedResult];
    } catch (err) {
      return [err as HttpErrorType, null];
    }
  }, []);

  return {
    loadingVerifiedLeadCreate: createLeadVerifiedProps.isLoading,
    onCreateLeadVerified,
  };
};
