// Core
import React from "react";

// Components
import { SigninForm } from "components/common/Forms/Signin";

// Bus
import { useLogin } from "bus/auth/hooks/useLogin";

type SigninRequestBlockProps = {
  testId?: string;
  signinInfoFormTestId?: string;
};

export const SigninRequestBlock: React.FC<SigninRequestBlockProps> = ({
  testId,
  signinInfoFormTestId,
}) => {
  const { loadingLogin, onLogin } = useLogin();

  return (
    <SigninForm
      testId={testId}
      onSubmit={onLogin}
      loading={loadingLogin}
      signinInfoFormTestId={signinInfoFormTestId}
    />
  );
};
