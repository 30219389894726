// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";

// Domains
import { authKeys } from "bus/auth/store";
import { authService } from "bus/auth/service";

// Utils
import { queryClient } from "init/queryClient";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";

type AuthLoginType = { token: string; profile: { customerId: number } };
type AuthLoginPayloadType = { login: string; password: string };
type AuthLoginCreateMutationMutateType = AuthLoginPayloadType;
type AuthLoginCreateMutationKeyType = [string];
type OnCreateAuthLoginMutationCbType = (data: AuthLoginPayloadType) => Promise<
  | [
      null,
      {
        token: string;
        profile: { customerId: number };
      },
    ]
  | [HttpErrorType]
>;
type UseAuthLoginType = {
  loadingAuthLogin: boolean;
  onLoginAuth: OnCreateAuthLoginMutationCbType;
};

export const useAuthLoginMutation = (): UseAuthLoginType => {
  const authLoginProps = useMutationAsync<
    AuthLoginType,
    AuthLoginPayloadType,
    AuthLoginCreateMutationMutateType,
    AuthLoginCreateMutationKeyType
  >({
    key: [authKeys.login],
    name: "createAuthLoginMutation",
    fetcher: authService.login,
    options: {
      onSuccess: (data) => {
        if (!data) {
          return;
        }
        queryClient.setQueryData<{ customerId: number } | null>([authKeys.login], (prevState) => {
          if (!prevState) {
            return null;
          }
          return {
            ...prevState,
            ...data,
          };
        });
      },
      onError: () => void 0,
    },
  });

  const onCreate = useCallback<OnCreateAuthLoginMutationCbType>(async (data) => {
    try {
      const response = await authLoginProps.mutateAsync(data);
      return [null, response];
    } catch (err) {
      return [err as HttpErrorType];
    }
  }, []);

  return {
    loadingAuthLogin: authLoginProps.isLoading,
    onLoginAuth: onCreate,
  };
};
