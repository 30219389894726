// Definitions
import {
  type FormatPhoneOptions,
  PhoneNumberFormatType,
  PhoneNumberFormatter,
} from "./phoneNumberFormat/phone-number-format";
import { UserCountryCodesEnum, UserCountryCodesKeys } from "bus/user/models";

const formatter = new PhoneNumberFormatter();
const phoneNumberFormats = formatter.getPhoneNumberFormats();

export const getFormatPhoneNumber = (
  phoneNumber: string,
  country: UserCountryCodesEnum = UserCountryCodesEnum.en,
): string | null => {
  const countriesFormat: Record<UserCountryCodesKeys, PhoneNumberFormatType> = {
    en: phoneNumberFormats.SPECIAL,
    fr: phoneNumberFormats.SPECIAL,
    de: phoneNumberFormats.INTERNATIONAL,
    at: phoneNumberFormats.INTERNATIONAL,
    nl: phoneNumberFormats.INTERNATIONAL,
  };

  const optionsFormatter: FormatPhoneOptions = {
    regionCode: country,
    formatPhone: countriesFormat[country],
  };

  return formatter.formatPhoneNumber(phoneNumber, optionsFormatter);
};
