// Components
import { Control, Controller } from "react-hook-form";
import { InputPassword } from "components/ui/FormFields/InputPassword";

// Definitions
import type { InputPasswordType } from "components/ui/FormFields/InputPassword";

/* eslint @typescript-eslint/no-explicit-any: 0 */
type WrappedFormPasswordType = InputPasswordType & {
  name: string;
  control?: Control<any>;
};

export const WrappedFormPassword = (props: WrappedFormPasswordType) => {
  const { name, control, ...rest } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <InputPassword {...rest} {...field} ref={field.ref} />}
    />
  );
};
