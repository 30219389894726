// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Definitions
import type { SigninType } from "bus/auth/models";
import { HttpStatusCode } from "models/Http";

// Utils
import { notificationService } from "utils/notifications";
import { getHttpErrorMessageWithTranslations } from "utils/notifications-message";
import { book } from "init/book";
import { useLeadVerifiedCreateMutation } from "bus/lead/hooks/useLeadVerifiedCreateMutation";

type UseCallbackCreateVerifiedLead = (data: SigninType) => void;

type UseLeadVerifiedCreateType = {
  loadingVerifiedLeadCreate: boolean;
  onCreateLeadVerified: UseCallbackCreateVerifiedLead;
};

export const useLeadVerifiedCreate = (): UseLeadVerifiedCreateType => {
  const router = useRouter();
  const { loadingVerifiedLeadCreate, onCreateLeadVerified } = useLeadVerifiedCreateMutation();

  const onCreate = useCallback<UseCallbackCreateVerifiedLead>((data) => {
    void (async () => {
      try {
        const [createLeadVerifiedError, createLeadVerifiedResult] =
          await onCreateLeadVerified(data);

        if (createLeadVerifiedError) {
          const isError400 = createLeadVerifiedError.status === HttpStatusCode.badRequest;
          const isError500 = createLeadVerifiedError.status >= HttpStatusCode.server;

          if (isError400 || isError500) {
            notificationService.showError(getHttpErrorMessageWithTranslations());
          }

          throw new Error("Something went wrong in lead verified create flow");
        }

        await router.push({
          pathname: book.signupProfile,
          query: { hash: createLeadVerifiedResult?.hash },
        });
      } catch (error) {
        console.warn(error);
        notificationService.showError(getHttpErrorMessageWithTranslations());
      }
    })();
  }, []);

  return {
    loadingVerifiedLeadCreate,
    onCreateLeadVerified: onCreate,
  };
};
