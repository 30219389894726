// Core
import { z } from "zod";
import { TFunction } from "i18next";

// Utils
import { getFieldLabel } from "utils/forms";

enum FormSigninEnum {
  login = "login",
  password = "password",
}
const i18NextPath = "inputs:";
const i18ReportPath = `${i18NextPath}errorMessages`;
const errorReport = {
  required: `${i18ReportPath}.required`,
  min: `${i18ReportPath}.min`,
  invalidPwdWhitespaces: `${i18ReportPath}.invalidPwdWhitespaces`,
};

const RX = {
  password: /^\S$|^\S[ \S]*\S$/,
};

export const inputConfig = {
  [FormSigninEnum.login]: {
    label: "",
    placeholder: getFieldLabel("login", true),
    required: errorReport.required,
  },
  [FormSigninEnum.password]: {
    label: "",
    placeholder: `${i18NextPath}password.placeholder`,
    required: errorReport.required,
    min: 8,
    minText: errorReport.min,
    patternWhitespaces: RX.password,
    invalidWhiteSpaces: errorReport.invalidPwdWhitespaces,
  },
};

export const signinForm = {
  shape: {
    login: "",
    password: "",
  },
  schema: (t: TFunction) => {
    return z.object({
      [FormSigninEnum.login]: z
        .string({
          required_error: t(inputConfig.login.required),
        })
        .trim(),
      [FormSigninEnum.password]: z
        .string({
          required_error: t(inputConfig.password.required),
        })
        .trim()
        .min(
          inputConfig.password.min,
          t(inputConfig.password.minText, {
            label: t(inputConfig.password.placeholder).toLowerCase(),
            count: inputConfig.password.min,
          }),
        )
        .regex(inputConfig.password.patternWhitespaces, t(inputConfig.password.invalidWhiteSpaces)),
    });
  },
};
