// Core
import { ReactElement, forwardRef } from "react";
import cx from "classnames";

// Components
import { Input } from "antd";

// Definitions
import { PasswordProps } from "antd/lib/input";

// Utils
import st from "./Styles.module.less";
import type { InputRefBaseType } from "models/Base";

export type InputPasswordType = PasswordProps & {
  maxLength?: number;
  hasDisabled?: boolean;
};

export const InputPassword = forwardRef<InputRefBaseType, InputPasswordType>(
  (props, ref): ReactElement => {
    const { className, hasDisabled = false, size = "large", ...rest } = props;

    const inputStyle = cx(
      st.input,
      {
        [st.disabled]: hasDisabled,
      },
      className,
    );

    return (
      <Input.Password
        {...rest}
        size={size}
        className={inputStyle}
        disabled={hasDisabled}
        ref={ref}
      />
    );
  },
);
InputPassword.displayName = "InputPassword";
